@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 
::selection {
  background: #5411db; /* WebKit/Blink Browsers */
  color: white;
}
::-moz-selection {
  background: #5411db; /* Gecko Browsers */
  color: white;
}

body {
  background-color: #fff;
  color: #3c4858;
  margin: 0;
  /* font-family: Roboto, Helvetica, Arial, sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}


blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}


legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}
.inlineCard {
  display: flex;
  align-items: center;
  padding: 8px 0px;
}
.inlineCard .img-outr {
  margin-right: 24px;
  width: 42px;
  text-align: center;
}
.footrwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: -15px;
  font-weight: bold;
}
.no-margin {
  margin: 0 !important;
}
html .form-control {
  width: 100%;
  margin-bottom: 24px;
}
span.input-tag {
  position: absolute;
  bottom: 5px;
  width: 82px;
  height: 38px;
  background-color: rgba(92, 23, 219, 0.2);
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0px;
  color: rgba(92, 23, 219, 1);
  font-size: 15px;
  font-weight: 500;
  left: 5px;
}
input#suffix-input {
  padding-left: 95px;
}
html .button-icon img {
  margin-right: 8px;
  max-width: 18px;
}
html .button-icon {
  text-transform: inherit;
  font-size: 15px;
}
html .generated-link {
  margin: 0 !important;
  box-shadow: none !important;
}
html .generated-link h4 {
  margin: 0;
  letter-spacing: 0px;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  max-width: 190px;
}
html .max-width-515 {
  max-width: 515px;
}

ul.SocialButton {
  display: flex;
  padding: 0;
  margin: 16px 0 0 0;
  list-style-type: none;
  justify-content: space-between;
}

ul.SocialButton li {
  margin: 14px 14px 0 0;
}

ul.SocialButton li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: rgba(32, 32, 32, 0.4);
  color: #fff;
  text-decoration: none;
}
span.icon-Wrap.quIcon,
ul.SocialButton li.quIcon a {
  background-color: rgba(92, 23, 219, 0.2);
  box-shadow: 0px 2px 4px rgba(92, 23, 219, 0.33);
}
span.icon-Wrap.fbIcon,
ul.SocialButton li.fbIcon a {
  background-color: rgba(46, 136, 255, 1);
  box-shadow: 0px 2px 4px rgba(46, 136, 255, 0.33);
}
span.icon-Wrap.mesIcon,
ul.SocialButton li.mesIcon a {
  background-color: rgba(99, 207, 55, 1);
  box-shadow: 0px 2px 4px rgba(99, 207, 55, 0.33);
}
span.icon-Wrap.instaIcon,
ul.SocialButton li.instaIcon a {
  background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
  box-shadow: 0px 2px 4px rgba(217, 46, 127, 0.33);
}
span.icon-Wrap.ytIcon,
ul.SocialButton li.ytIcon a {
  background-color: rgba(255, 2, 0, 1);
  box-shadow: 0px 2px 4px rgba(255, 2, 0, 0.33);
}
span.icon-Wrap.snapcIcon,
ul.SocialButton li.snapcIcon a {
  background-color: rgba(248, 245, 38, 1);
  box-shadow: 0px 2px 4px rgba(248, 245, 38, 0.33);
}
span.icon-Wrap.telegIcon,
ul.SocialButton li.telegIcon a {
  background-color: rgba(0, 136, 204, 1);
  box-shadow: 0px 2px 4px rgba(0, 136, 204, 0.33);
}
span.icon-Wrap.twIcon,
ul.SocialButton li.twIcon a {
  background-color: rgba(0, 172, 238, 1);
  box-shadow: 0px 2px 4px rgba(46, 136, 255, 0.33);
}

ul.SocialButton li:not(.selected) a {
  background: rgba(32, 32, 32, 0.4);
  box-shadow:none;
}

ul.SocialButton li:last-child {
  margin-right: 0;
}
/* ul.SocialButton li a:hover {
  
} */
.max-width-515 div[class*='makeStyles-cardBody'] {
  padding: 30px;
}
.generated-link div[class*='makeStyles-cardHeader'] {
  margin: 0 !important;
  box-shadow: none;
  display: flex;
  align-items: center;
}
.generated-link div[class*='makeStyles-cardHeader'] img {
  margin-right: 14px;
}
html .generated-link .form-control button {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 48px;
}
html .generated-link .form-control input#link-input {
  border-color: rgba(92, 23, 219, 0.33);
  background-color: rgba(92, 23, 219, 0.1);
  color: #5C17DB;
}
.generated-link .button-group {
  border-top: 1px solid #E3E3E3;
  padding-top: 20px;
}
.generated-link .button-group button {
  margin: 0 16px 0 0;
}
html .MuiButton-outlined {
  background-color: transparent;
  border: 1px solid #eaad50;
  color: #eaad50;
  margin-right: 0;
}
ul.inline-buttons {
  margin: 0;
  list-style-type: none;
  padding: 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
ul.inline-buttons li {
  margin-left: 70px;
}
ul.inline-buttons a {
  color: #202020;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
  display: flex;
  align-items: center;
}
ul.inline-buttons a img {
  margin-right: 5px;
}
.accountwrap h6 {
  margin: 0 0 25px 0;
  font-size: 16px;
  color: #202020;
  letter-spacing: 0px;
  font-weight: bold;
}
html .accountwrap .form-control {
  max-width: 335px;
}
html .accountwrap .form-control:last-child {
  margin-bottom: 0;
}
html .accountwrap div[class*='makeStyles-cardFooter'] {
  padding-top: 0;
}
fieldset.planWrap {
  width: 100%;
}
ul.radiWrap {
  margin: 0;
  list-style-type: none;
  padding: 0;
}
ul.radiWrap li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 3px #20202024;
  height: 60px;
  margin-bottom: 24px;
  padding: 0 16px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.2s;
}
ul.radiWrap li.active {
  box-shadow: 0px 1px 3px rgba(32, 32, 32, 0.14);
  border: 4px solid #5C17DB;
  border-radius: 4px;
}
ul.radiWrap li > label > span:first-child {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0;
  left: 0;
  right: 0;
  padding: 0;
}
.form-control label.MuiFormLabel-root {
  font-weight: bold;
}
ul.radiWrap li > label > span:first-child > span {
  opacity: 0;
}
ul.radiWrap li > label > span:last-child {
  padding-left: 10px;
  font-weight: bold;
  color: #202020;
}
ul.radiWrap li.active > label > span:last-child {
  color: #5C17DB;
}
.borderWrap span {
  color: #5C17DB;
  font-weight: bold;
}
form.cusForm input.sr-input {
  width: 100%;
  border: 1px solid #ced4da;
  padding: 10px 12px;
  position: relative;
  font-size: 15px;
  box-sizing: border-box;
  min-height: 48px;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  background-color: #fff;
}
.cus-tabl-body div[class*='MuiTextField-root'] > div.Mui-focused,
form.cusForm .sr-combo-inputs-row .sr-card-element.StripeElement--focus,
form.cusForm input.sr-input:focus {
  box-shadow: rgba(84, 17, 219, 0.25) 0 0 0 0.2rem;
  border-color: #5411db;
}
.cus-tabl-body div[class*='MuiTextField-root'] > div.Mui-focused:after,
.cus-tabl-body div[class*='MuiTextField-root'] > div.Mui-focused:before {
  display: none;
}
form.cusForm .sr-combo-inputs-row .sr-card-element {
  padding: 15px 8px;
  border: 1px solid #ced4da;
  margin: 16px 2px;
  border-radius: 4px;
}
html .minHeight-382 {
  min-height: 382px;
}
.cus-tabl-body div[class*='MuiTextField-root'] > div {
  height: 100%;
  width: 100%;
  border: 1px solid #5C17DB;
  border-radius: 4px;
  background-color: #fff;
  padding-left: 14px;
}
.cus-tabl-body div[class*='MuiTextField-root'] {
  width: 302px;
  height: 48px;
  padding: 0;
}
.cus-tabl-body div[class*='MuiTextField-root'] div:before {
  opacity: 0;
}
.cus-tabl-body thead.MuiTableHead-root {
  display: none;
}
.cus-tabl-body table.MuiTable-root {
  margin-top: 16px;
}

.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root {
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 1px 3px #20202024;
  border-radius: 4px;
  margin-bottom: 16px;
}
.cus-tabl-body {
  margin-top: -80px;
}
.cus-tabl-body div[class*='MuiTextField-root'] span.material-icons[title="Search"] {
  font-size: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAR9JREFUOE+tlK1zAjEQRx8OiazkT6hEViIrKysrK5FIZCUSiURWVlZWIisrK5HMu7ncJLnMcR/sTIbhkn357WZ3Z7RtAayAJfBQb1+AM/AD/BZ8mk+zbPMJcHWZwBPwXzoUA5+BxxuwsK3iA/CXnw/APspyXxV+lIDm7L2nsvzYF+BKcriuH2EMs6XSkN+i1xwD3ce5FLgdQ4l8fJymlO4B/AS+wwUCN8B8gsqWwiH1V7p3B1iXlanQFnsdqdB2POZl43+BgodaEm5Q6K/FbfkMzaWtJzQJOahysrzU8CFKE2g+bQTZ1w4JVedmziwRuyuMNs800BIwVmwKdNTBFULzu3mPoRb3oQt4K+wSdD8F6IVCzbsVYgSTgXF6qmF7BTXQMi12KEpfAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
}
.cus-tabl-body > .MuiToolbar-root {
  padding: 0;
}

.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td {
  display: inline-flex;
  border: none;
  min-height: 77px;
  align-items: center;
  padding: 14px;
    flex: 0 0 13%;
  width: auto !important;
}
.cus-tabl-body div[class*="makeStyles-cardHeader"] > div, .cus-tabl-body div[class*="makeStyles-cardHeader"] {
  margin: 0 !important;
}
.cus-tabl-body div[class*="makeStyles-card"] {
  margin-top: 8px;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(1) {
  padding: 0;
  flex: 0 0 auto;
  max-width: 48px;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(3) {
  flex: 0 0 49%;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(6) {
  flex: 0 0 auto;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(2) a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 94%;
  display: flex;
  align-items: center;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(2) {
  flex: 0 0 17%;
  max-width: 192px;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td a {
  font-size: 16px;
  color: #202020;
  text-decoration: none;
  line-height: normal;
}
.clicksWrap {
  font-size: 15px;
  color: #202020;
  display: flex;
  align-items: center;
}
.clicksWrap {
  font-size: 15px;
  color: #202020;
  display: flex;
  align-items: center;
}
.clicksWrap img {
  margin-right: 8px;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(1):last-child {
  flex: 0 0 100%;
  padding: 0 0;
  max-width: 100%;
}
span.icon-Wrap {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: inline-block;
  vertical-align: middle;
  background-color: #2E88FF;
  border-radius: 4px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
span.icon-Wrap img {
  margin: auto;
  vertical-align: middle;
  max-width: 22px;
}

.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td a:hover {
  color: #5411db;
}
.cus-tabl-body table.MuiTable-root > tbody > tr:last-child:first-child > td:last-child:first-child {
	padding: 10px;
	box-sizing: border-box;
	text-align: center !important;
	font-weight: bold;
}
.cus-tabl-body table.MuiTable-root > tbody > tr:last-child:first-child {
	height: auto !important;
}
.tr-menu .MuiBox-root li.MuiButtonBase-root {
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid rgba(75, 75, 75, 0.13);
  height: auto;
  min-height: 40px;
  min-width: 200px;
}
.tr-menu .MuiBox-root {
  padding: 0;
}
.tr-menu .MuiBox-root li.MuiButtonBase-root img {
  margin-right: 14px;
  max-height: 20px;
  width: auto;
}
@media (max-width: 1250px) {
  .cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(3) {
    flex: 0 0 46%;
}
}
@media (max-width: 1150px) {
  span.icon-Wrap {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
  }
  span.icon-Wrap img {
    max-height: 16px;
}
/* .cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(3) {
  flex: 0 0 46%;
} */
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td {
  flex: 0 0 16%;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(3) {
  flex: 0 0 35%;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(2) {
  flex: 0 0 19%;
  max-width: 192px;
}
}
@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
  /* .badge-primary-background-color: #9c27b0; */
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
  box-shadow: none;
}
.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}
.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}
.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder img {
  margin-top: auto;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}
.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}
.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  transform: translateY(-13%);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}
.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}
* {
  letter-spacing: normal !important;
}
@media (min-width:641px) {
  .onlyMobile {
    display: none !important;
}
}
@media (max-width:991px) {
  header .MuiButton-label span {
    font-size: 14px !important;
}
}
@media (max-width:959px) {
  button.buttonMobile svg.MuiSvgIcon-root {
    width: 40px;
    height: auto;
  }
  header .MuiToolbar-gutters {
    padding-left: 20px;
    padding-right: 4px;
}
ul.inline-buttons li {
  margin-left: 20px;
}
ul.inline-buttons a {
  font-size: 15px;
}
.clicksWrap,
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td a {
  font-size: 12px;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(3) {
  flex: 0 0 auto;
  font-size: 12px;
  margin-right: auto;
  word-break: break-all;
  white-space: normal;
  max-width: 210px;
}
}
@media (max-width:767px) { 
  html header > div a {
    margin-right: 6px !important;
    white-space: nowrap;
}
header .MuiToolbar-root > div:nth-child(2) {
  padding-left: 10px;
}
ul.inline-buttons li {
  margin-left: 10px;
}
ul.inline-buttons a {
  font-size: 14px;
}
ul.inline-buttons a img {
  margin-right: 5px;
  max-height: 18px;
  width: auto;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(2) {
  flex: 0 0 16%;
  max-width: 100px;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td {
  padding: 14px 8px;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(3) {
  max-width: 180px;
}

}

@media (max-width:640px) {
  header .MuiToolbar-root > div:nth-child(2) {
    padding-left: 0px;
    position: fixed;
    left: 0;
    height: 100%;
    flex-direction: column;
    z-index: 999;
    top: 56px;
    background-color: #5411db;
    width: 100%;
    box-sizing: border-box;
    max-height: 0;
    overflow: hidden;
    height: 100%;
    transition: all 0.2s;
}
header .MuiToolbar-root.expanded > div:nth-child(2) {
  max-height: 100%;
}
ul.inline-buttons li {
  margin-left: 0;
}
 }
 @media (max-width:599px) {
  .cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(1):not(:last-child) {
    position: absolute;
    top: 0;
    left: 0;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(2) {
  padding-left: 30px;
}
  html .MuiContainer-root {
    padding-left: 8px;
    padding-right: 8px;
  }
  header .MuiToolbar-gutters {
    padding-left: 20px;
  }
 html .smOnMobile {
    max-width: 31.3%;
    display: inline-flex;
    flex-direction: column;
    margin-right: 3%;
    vertical-align: top;
    margin-bottom: 20px;
}
.smOnMobile div[class*="makeStyles-cardHeader"] {
  padding: 0;
}
.smOnMobile .inlineCard {
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-content: center;
  padding: 5px;
  min-height: 85px;
}
.smOnMobile .inlineCard .img-outr {
  margin: 0 auto;
  width: 30px;
}
.smOnMobile .inlineCard .img-outr img {
  max-width: 100%;
  max-height: 30px;
}
.smOnMobile .inlineCard p {
  font-size: 12px;
  line-height: 18px;
}
html .smOnMobile:last-child {
  margin-right: 0;
}
html .max-width-515 {
  max-width: 100%;
 }
 ul.SocialButton {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.max-width-515 div[class*="makeStyles-cardBody"] {
  padding: 16px;
}
html .max-width-515 div[class*="makeStyles-cardBody"] .button-icon {
  width: 100%;
}
.generated-link .button-group .button-icon:first-child {
  margin-bottom: 16px;
}
html .accountwrap .form-control,
.MuiGrid-root .MuiButton-root {
  width: 100%;
  max-width: 100%;
}
ul.inline-buttons {
  margin-bottom: 20px;
}
ul.inline-buttons li {
  margin-left: 10px;
 }
 .cus-tabl-body {
  margin-top: 0;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root {
  flex-wrap: wrap;
  padding: 14px 10px;
  position: relative;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td {
  flex: 0 0 auto;
  padding: 0 0;
  min-height: 0;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(3),
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(2) {
    flex: 0 0 100%;
    max-width: 100%;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(6) {
  position: absolute;
  top: 0px;
  right: -10px;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(3) {
	border-bottom: 1px solid #F5F3F9;
	width: 100%;
	padding: 12px 0 10px 0;
	margin-bottom: 10px;
	color: rgba(32, 32, 32, 0.66) !important;
}
.cus-tabl-body table.MuiTable-root tr.MuiTableRow-root td:nth-child(4) {
	margin-right: 20px;
}
.cus-tabl-body div[class*="MTableToolbar-spacer"] {
  display: none;
}
.cus-tabl-body div[class*='MuiTextField-root'] {
  width: 100%;
}
 }
